
import EleIconPicker from 'ele-admin-pro/packages/ele-icon-picker';
import {
    QuestionCircleOutlined
} from '@ant-design/icons-vue';

export default {
    name: 'MenuEdit',
    components: {
        EleIconPicker,
        QuestionCircleOutlined
    },
    emits: [
        'done',
        'update:visible'
    ],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 修改回显的数据
        data: Object,
        // 全部菜单数据
        menuList: Array
    },
    data() {
        return {
            // 表单数据
            form: this.initFormData(this.data),
            // 表单验证规则
            rules: {
                title: [{
                    required: true,
                    type: 'string',
                    message: this.$t('请输入菜单名称'),
                    trigger: 'blur'
                }],
                index: [{
                    required: true,
                    type: 'number',
                    message: this.$t('请输入排序号'),
                    trigger: 'blur'
                }]
            },
            // 提交状态
            loading: false,
            // 是否是修改
            isUpdate: false
        };
    },
    watch: {
        data() {
            this.isUpdate = !!(this.data && this.data.menu_id);
            this.form = this.initFormData(this.data);
            if (this.$refs.form) {
                this.$refs.form.clearValidate();
            }
        }
    },
    methods: {
        /**
         * 保存编辑
         */
        save() {
            this.$refs.form.validate().then(() => {
                this.loading = true;
                this.$http.post('/admin/menu/admin/update',
                    Object.assign({}, this.form, {
                        parent_id: this.form.parent_id || 0
                    })
                ).then(res => {
                    this.loading = false;

                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.updateVisible(false);
                        this.$emit('done');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }).catch(() => {});
        },
        /**
         * 更新visible
         * @param value
         */
        updateVisible(value) {
            this.$emit('update:visible', value);
        },
        /**
         * 初始化form数据
         * @param data
         */
        initFormData(data) {
            let form = {
                enabled: true,
                target: '_self',
                open_type: 0,
                index: 1,
                user_activation: 1
            };

            if (data) {
                Object.assign(form, data, {
                    parent_id: data.parent_id === 0 ? null : data.parent_id
                });
            }

            return form;
        },
        /**
         * 判断是否是网址
         * @param url
         */
        isUrl(url) {
            return url && (
                url.startsWith('http://') ||
                url.startsWith('https://') ||
                url.startsWith('://'));
        }
    }
}
